<template>
  <div class="table-custom table-custom">
    <div>
      <table>
        <thead class="top-table">
          <tr>
            <th v-for="(item, index) in table_header" :key="index" role="columnheader" data-column-id="date" scope="col" class="header-table">{{item}}</th>
          </tr>
        </thead>
        <tbody >
          <tr v-for="(row, index) in table_body" :key="index">
            <th v-for="(cel, key) in row" :key='key' scope="row" class="table-row">{{cel}}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
  import TableCustom from "@/components/helpers/Table_custom.vue";
  import Vue from 'vue';

  export default {
    name: 'TableCustom',
    props: {
      table_header: {type: Array},
      table_body: {type: Array},
      table_obj: {type: Object}
    },
    data() {
      return {
      };
    },
    computed: {
    },
    mounted() {
      const MyComponentConstructor = Vue.extend(TableCustom);
      const table = new MyComponentConstructor();
    }  
  }
</script>



<style lang="scss">

#stocks{
  .table-custom{
    height: auto;
    width:auto;
    margin-top:25px;
    margin-bottom:50px;
  }
  .header-table{
    margin-right:50px !important;
  }
}
.header-table{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.top-table{
  
}

.table-custom{
  height: 280px;
  width: 100%;
  background-color: #F3F4F7;
  overflow-y: scroll;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.table-row{
  font-size: 12px;
  background-color: white;
  font-weight: normal;
}

#best-bullish{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: 417px;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#hot-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: 417px;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#best-sectors{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: 417px;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#portfolio-changes{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 417px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: 417px;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#twin-souls{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 417px;
    height: 40px;
  }
  .table-custom{
    height: 180px;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#ia{
  .header-table{
    font-size: 12px;
    color: var(--color-text-primary);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 139px;
    height: 40px;
  }
  .table-custom{
    height: 280px;
    width: 600px;
    background-color: var(--color-surface);
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
    color: var(--color-text-primary);
  }

  .table-row{
    font-size: 12px;
    height: 80px !important;
    background-color: var(--color-active-surface);
    font-weight: normal;
    color: var(--color-text-primary);
  }
  .top-table{
    width: 100%;
  }
}

#waiting-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: 417px;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}
#last-ipos{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: 280px;
    width: 417px;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}
</style>
