<template>
  <div id="main-container" v-if="_g_IsUserLogged">
    <div v-if="_g_UserFeatures.permissions.artificial_inteligence" class="results-main-container">
      <div v-if="test_do_it" class="inteligence-artificial" id="ia">
        <h3>Inteligencia Artificial</h3>
        <div class="ia-buttons-box table-box">
          <a :href="test_redirect">
            <Button :icon_permission="false" :button_name="button_name_test" :button_key="button_key_test"/>
          </a>
          <CheckBox v-if="_g_UserFeatures.permissions.adaptative_artificial_inteligence" @_emit_method_checkbox="_getValueCheckBox" :checkbox_id="checkbox_id" :checkbox_key="checkbox_key" :label_name="checkbox_label_name" :checkbox_state="checkbox_state" :button_icon="button_icon" />
          <Button @_emit_method_button="_redirectToStockchart" :icon_permission="false" :button_name="button_name_stockchart" :button_key="button_key_stockchart" :button_icon="button_icon" class="btn-redirect"/>
        </div>
        <div id="tables" class="tables-container mdc-layout-grid">
          <div class="table-box mdc-layout-grid__cell_2">
            <p class="table-title">Valores en Europa</p>
            <Table :table_header="table_header" :table_body="table_body_eu" style="width: 95%; margin-right: 10px;"/>
          </div>
          <div class="table-box mdc-layout-grid__cell_2">
            <p class="table-title">Valores en USA</p>
            <Table :table_header="table_header" :table_body="table_body_usa" style="width: 95%;margin-right: 10px;"/>
          </div>
          <div class="table-box mdc-layout-grid__cell_2">
            <p class="table-title">Valores en Resto del mundo</p>
            <Table :table_header="table_header" :table_body="table_body_rest" style="width: 95%;margin-right: 10px; margin-bottom: 20px;"/>
          </div>
        </div>
      </div> 
      <div v-else-if="test_do_it == false" :class="is_mobile_device ? 'not-results-mobile' : 'not-results-main-container'">
        <div v-if="_g_IsUserLogged" class="inteligence-artificial" id="ia">
          <h3>Inteligencia Artificial</h3>
          <p>No tienes datos en las tablas de resultados, realice el test para que la máquina de eurekers te muestre tu compatibilidad de inversión con los valores de Eurekers.</p>
          <div class="ia-buttons-box table-box">
            <button @click="_go_to_start_test()" class="do-test-button">Realizar test</button>
          </div>
        </div> 

        <div v-if="btn_test_pressed" :class="is_mobile_device ? '' : 'secondary-ia-container'">
          <div class="text-ia">
            <p><b>La duración estimada de este test es de unos 20 minutos.</b> <br>
              <span class="ia-test-description"> Vas a empezar el test de compatibilidad. Deberás evaluar las gráficas entre 1 y 5 puntos.
              Para que tu perfil de compatibilidad sea más preciso, necesitamos que seas riguroso con tus respuestas.
              Tómate tu tiempo evaluando cada entrada y decide en qué grado te gusta o no te gusta cada una. </span></p>
          </div>
          <div class="button-start-test">
            <a :href="test_redirect">
              <Button :icon_permission="false" :button_name="button_start_test" :button_key="button_key_test"/>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="not-allowed-artificial-intelligence-container">
      <p class="not-allowed-artificial-intelligence-title"> SIN ACCESO </p>
      <p class="not-allowed-artificial-intelligence"> Su plan actual no tiene acceso a esta herramienta. <a href="/app/store" class="link-to-store"> Pulse aquí para cambiar de plan. </a> </p>
    </div>
  </div>
  <div id="main-container" class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-1-phone" v-else style="text-align: center;">
    <div v-if="!_g_User.is_basic_user" class="container-image mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--align-middle">      
      <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/ia_adaptativa.jpg">
      <div class="text-center">
        <h2>Inteligencia artificial adaptativa</h2>
        <p>Utiliza esta herramienta si quieres ahorrarte la tarea de buscar activamente valores que se ajusten a tu perfil inversor. Una vez realices el test inicial para conocer tus predilecciones, el sistema siempre te servirá en bandeja los valores que sabe que te gustan, tanto en Europa como en el resto del mundo. Analiza la composición de la cartera seleccionada según tus preferencias y muestra unos porcentajes de compatibilidad con los valores, que varían dependiendo de la situación del mercado. En definitiva, vigilará tus gustos y los adaptará al presente financiero, conociendo los mejores sectores o países para invertir, así como los valores que ya posees para valorar los que mejor se adapten al momento actual.</p>
      </div> 
      <div class="button-container">
        <button class="login-button" @click="_redirectToLogin()">LOGIN</button>
      </div> 
    </div>
    <div v-else class="unauthorized">
      <span>Usted no está autorizado para visitar esta página. Si la página a la que está accediendo es correcta, es posible que tenga que actualizar su plan.</span>
    </div>       
  </div>
</template>

<script>
  import Table from '@/components/helpers/Table_ia.vue';
  import Button from '@/components/helpers/Button.vue';
  import CheckBox from '@/components/helpers/CheckBox.vue';
  import APICaller from "@/components/codeComponents/APICaller.js";
  import Highcharts from "highcharts";
  import { mapGetters } from "vuex";


  export default {
    mixins: [APICaller],
    name: 'InteligenceArtificial',
    components: {
      Table,
      Button,
      CheckBox
    },
   
    data() {
      return {
        table_header: [],
        table_body_eu: [],
        table_body_usa: [],
        table_body_rest: [],

        button_name_test: 'REALIZAR TEST',
        button_start_test: 'Comenzar test',
        button_key_test: 'ia_button_test',

        button_name_stockchart: 'Ver todos en el Graficador Dinámico',
        button_key_stockchart: 'ia_button_stockchart',
        button_icon: '',
        
        checkbox_key: 'ia_checkbox_apply',
        checkbox_id: 'ia_checkbox_apply',
        checkbox_state: true,
        checkbox_label_name: ' Aplicar IA adaptativa',
        user_uid: '',
        user_feature: '',
        adaptative:true,
        test_do_it: "",

        btn_test_pressed: false,
        
        test_redirect: process.env.VUE_APP_API + '/test-graficos-potenciales',
      };
    },
    created(){     
       var self = this;
      setTimeout( function(){       
        self._user_ia_tables();
      }, 1000);
    },
    mounted() {
      //this._addImageToTable();
    },
    updated() {           
      this._addImageToTable();         
    }, 
    computed:{
      //this._addImageToTable();
      ...mapGetters(["_g_IsUserLogged"]),
      ...mapGetters(['is_mobile_device']),                                                                                                 
      ...mapGetters(['_g_User']),
      ...mapGetters(["_g_UserFeatures"]),
    },
    methods: {
      _redirectToLogin() {
        window.location.href = "/user/login"; // Reemplaza la URL con la que desees redirigir
      },
      _getValueCheckBox(value) {
        this.adaptative = value[0];
        var self = this;
      setTimeout( function(){        
        self.table_body_eu = [];
        self.table_body_rest = [];
        self.table_body_usa = [];      
        self._user_ia_tables();
      }, 1);
      },
      _redirectToStockchart() {
        this.$router.push('/app/graficador-dinamico/compatibility')
      },
      _user_ia_tables(){  
        let success = (response) => {
          if(response.data[0].rows.length == 0){
            this.test_do_it = false;
          }else{
            this.test_do_it = true;
            this._data_load_in_tables(response.data,0);
            this._tableHeader(response.data,0);
            this._data_load_in_tables(response.data,1);
            this._data_load_in_tables(response.data,2);
          }         
        };

        let url = "/api/v1/compatibility/tables/" + this.adaptative;

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('187', 'Error recuperando tablas.');

        this._getAPICall(url, successHandler, failureHandler);
      },
      _tableHeader(data,value){
        this.table_header = [];      
        var json = data[value].header;
        for(var i=1;i<=Object.keys(json).length;i++){
          this.table_header.push(json[i]["data"]);
        }
        
      },
      _data_load_in_tables(data,tabla){       
        var json = data[tabla].rows;     
        for(var i=1;i<=Object.keys(json).length;i++){          
          if(this.adaptative){
            var elemento1 = {
              posicion: json[i]["position"],
              ticker: json[i]["ticker_info"]["ticker"],
              grafico: json[i]["graph"],
              tipo_entrada: json[i]["entry_type"],
              compatibilidad: (json[i]["compatibility"]["data"]*100).toFixed(2) + "%",
              modificador: json[i]["modifier_value"]["data"].toFixed(2) + "%",                       
              estado:json[i]["status"]["data"],
            };
            if(tabla == 0){
              this.table_body_eu.push(elemento1);
            }else if(tabla == 1){
              this.table_body_rest.push(elemento1);
            }else{
              this.table_body_usa.push(elemento1);
            }
          }else{
            var elemento = {
              posicion: json[i]["position"],
              ticker: json[i]["ticker_info"]["ticker"],
              grafico: json[i]["graph"],
              tipo_entrada: json[i]["entry_type"],
              compatibilidad: (json[i]["compatibility"]["data"]*100).toFixed(2) + "%",           
              estado:json[i]["status"]["data"],
            };
            if(tabla == 0){
              this.table_body_eu.push(elemento);
            }else if(tabla == 1){
              this.table_body_rest.push(elemento);
            }else{
              this.table_body_usa.push(elemento);
            }
          }
      } 
      },     

      _add_graph(id,value,ticker) {
        var ide = id;
        var valuee = value;
        Highcharts.chart(ide, {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false
        },
        title: {
            text: valuee + "%",
            align: 'center',
            verticalAlign: 'middle',
            y: 20,
            style: {
              fontSize: '12px'
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '75%'],
                size: '110%'
            }
        },
        series: [{
            type: 'pie',
            name: ticker,
            innerSize: '50%',
            data: [
                ['', valuee],
                {
                    name: 'Other',
                    y: 100-valuee,
                    dataLabels: {
                        enabled: false
                    }
                }
            ]
          }]
        });
          
      },    
      
      _addImageToTable: function(){
        var graf = document.getElementsByClassName("table-row");
        //var x;
        var i;
        var l;
        //var f;
        if(this.adaptative){
          i=6;
          l=4;
          //x=2;
          //f=5;
        }
        else{
          i=5;
          l=3;
          //x=1;
          //f=4;
        }          
        //var j=0;
        
          
        while(graf[i] != undefined){
          if(graf[i].innerHTML == "arrow-down-red"){
            graf[i].innerHTML = "<div class='material-icons-outlined red'>arrow_downward</div>";
            graf[i].style.color = "red";
          }
          else if(graf[i].innerHTML == "arrow-up-green"){
            graf[i].innerHTML = "<div class='material-icons-outlined green'>arrow_upward</div>";
            graf[i].style.color = "green";
          }
          if(graf[i-l].innerHTML != ""){
            graf[i-l].innerHTML = graf[i-l].innerHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
          } 
          //añadir grafico a tabla
          /*graf[i-x].setAttribute('id','chart '+j);
          graf[i-x].style.height = '80px'; 
          var withoutpercentaje = graf[i-x].innerHTML.substring(0,3); 
          this._add_graph('chart '+j, parseFloat(withoutpercentaje), graf[i-f].innerHTML);
          var credits = document.getElementsByClassName("highcharts-credits");
          credits[j].style.display = "none";*/

          if(this.adaptative){
            i += 7;  
          }else{
            i += 6;
          }            
          //j++;      
        }
      },
      _go_to_start_test(){
        document.getElementById("ia").style.display = "none";
        this.btn_test_pressed = true;
      }
  }
}
</script>


<style lang="scss" scoped>
  html{
    overflow-y: scroll;
  }
  
  h3 {
    margin-top: 0;
    padding-top: 50px;
  }

  h3, p {
    color: var(--color-text-primary);
  }

  #main-container {
    background-color: var(--color-background);
  }

  .tables-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }
  .not-results-main-container{
    margin-left: 40px;
  }
  .ia-buttons-box .mdc-button {
    width: max-content;
    margin: 20px 0 ;
    display: block;
  }

  @media screen and (max-width: 599px){
    .table-box {
      width: 100%;
    }
    .btn-redirect{
      width: 325px !important;
    }
  }

  @media (min-width: 600px) {
    .mdc-layout-grid__cell--span-4 {
      grid-column-end: span 6;
    }
  }

  @media (min-width: 890px) {
    .results-main-container {
      margin-left: 40px;
    }
  }

  @media (max-width: 889px) {
    .results-main-container {
      margin-left: 25px;
    }
  }
  

  .highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 500px;
    margin: 1em auto;
}


.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 5px auto;
    text-align: center;
    width: 100%;
    max-width: 90px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
.highcharts-credits{
  display:none !important;
  color:white;
}
th { 
  height:90px !important;
}

a {
  text-decoration: none;
}
.do-test-button{
  border: none;
  background-color: #0faed7;
  color: white;
  height: 40px;
  width: 200px;
  border-radius: 25px;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.secondary-ia-container{
  text-align: center;
  position: absolute;
  top: 40%;
}

.text-ia{
  width: 50%;
  margin: auto;
}

.not-results-mobile{
  position: absolute;
  top: 30%;
  text-align: center;
}

.table-custom {
  margin-bottom: 25px;
}

.container-image{
    /* Aplica un margen automático para centrar el div horizontalmente */
    margin: 0 auto;
    /* Ajusta el ancho máximo del contenedor */
    max-width: 800px;
    max-height: auto;
    
  }



  .container-image img{
    max-width: 500px;   
    max-height: 800px;
    
    border-radius: 5px;
  }

  @media screen and (min-width: 768px) {
    .container-image img {
      width: 500px;
      margin: 20px;
    }
  }

  .text-center {
    text-align: center;
    margin: 20px;
  }

  .container-image p {    
    text-align: justify;
  }

  .unauthorized {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    justify-content: center;
    font-size: 20px;
  }

  .login-button{
    color: white;
    background-color: #00add8;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    width: 150px;
    margin-top: 20px;
    font-size: 14px;
  }
  .login-button:hover{
    background-color: #00bdec;
  }

  .not-allowed-artificial-intelligence-container{
    text-align: center;
    margin-top: 50px;
  }
  
  .link-to-store{
    color: #35add8 !important;
  }

  .not-allowed-artificial-intelligence-title{
    color: #FF9B00;
    font-weight: bold;
  }

</style>